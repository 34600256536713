import { Box, Grid } from '@mui/material';
import { packagesTypes } from '../../types';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import PropTypes from 'prop-types';
import InfoLabel from '@/src/components/info-label/InfoLabel';
import TitleWithStatus from '@/src/components/title-with-status/TitleWithStatus';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const PackageTypeDetailForm = ( props: Props) => {

  const {  close, data, t, isLoading } = props;

  return (
    <>
      {isLoading ? <CircularSpinner /> : (
        <Grid sx={{ p: 'none'}} >
          <TitleWithStatus
            title={`${t('info_package_type_title', { ns: 'packages' })}`}
            statusText=""
            statusBg=""
            onClose={close}
            addMargin={false}
          />
          <Box component="div" sx={{ width: '100%', textAlign: 'right', pt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={12} sx={{ padding: '10px 0 0' }}>
                <InfoLabel
                  first={t('modal_create_package_input_name', { ns: 'packages' })}
                  second={data?.name as string}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={12}>
                <InfoLabel
                  first={t('modal_create_package_input_price', { ns: 'packages' })}
                  second={data?.price as number}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        )}
    </>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps {
  close : () => void;
  data : packagesTypes | undefined,
  isLoading: boolean,
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PackageTypeDetailForm.propTypes = propTypes;

export default PackageTypeDetailForm;
