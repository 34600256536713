import * as Yup from 'yup';
import { VALID_PASSWORD_REGEX } from '@/src/utilities/helpers/constants';

export const validate = ({ t, adminAlreadyExist }: any) =>
  Yup.lazy(() =>
    Yup.object().shape({
      adminGroups: Yup.array().min(1, t('required_field', { ns: 'errors' })),
      confirmPassword: Yup.string().when([], {
        is: () => !adminAlreadyExist,
        then: Yup.string()
          .required(t('required_field', { ns: 'errors' }))
          .oneOf([Yup.ref('password'), null], t('user_form_passwords_field_do_not_match_validation', { ns: 'users' })),
        otherwise: Yup.string().notRequired(),
      }),
      countryCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      countryIsoCode: Yup.string().required(t('required_field', { ns: 'errors' })),
      email: Yup.string()
        .required(t('required_field', { ns: 'errors' }))
        .email(t('user_form_invalid_email_field_validation', { ns: 'users' }))
        .trim(),
      name: Yup.string().required(t('required_field', { ns: 'errors' })),
      password: Yup.string().when([], {
        is: () => !adminAlreadyExist,
        then: Yup.string()
          .min(8, t('change_password_screen_new_password_field_min_length_validation', { ns: 'auth' }))
          .max(20, t('change_password_screen_new_password_field_max_length_validation', { ns: 'auth' }))
          .required(t('required_field', { ns: 'errors' }))
          .matches(
            VALID_PASSWORD_REGEX,
            t('change_password_screen_new_password_field_invalid_regex_validation', { ns: 'auth' }),
          ),
        otherwise: Yup.string().notRequired(),
      }),
      phoneNumber: Yup.string().required(t('required_field', { ns: 'errors' })),
      surname: Yup.string().required(t('required_field', { ns: 'errors' })),
    }),
  );
