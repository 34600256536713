import { useQuery } from '@tanstack/react-query';
import { getRoles } from '@/src/api/endpoints/users';

export const useGetRoles = (clientTenantCode: string) => {
  const {
    data: rolesList,
    isLoading,
    refetch,
  } = useQuery(['roles'], () => getRoles(), {
    select: (data) => data?.data?.roles,
    enabled: false,
  });

  return { rolesList, isLoading, refetch };
};
