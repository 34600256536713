import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { packagesTypes } from '../../types';
import { validate } from './EditPackageTypeForm.validate';
import CreatePackageTypeForm from './EditPackageType';
import { getPackageTypeById, updatePackageType } from '@/src/api/endpoints/packages';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { AlertColor } from '@mui/material';
import { UpdatePackageTypeRequest } from '@/src/api/types/types';
import { useEffect, useMemo } from 'react';

const EditPackageFormContainer = (props: Props) => {
  const { close, row, setSnackBarMessage } = props;
  const { t } = useTranslation();


  const { mutate: updatePackageTypeMutate } = useMutation(
    ({ id, req }: { id: number; req: UpdatePackageTypeRequest }) => updatePackageType(id, req),
    {
      onSuccess: async () => {
        close();
        setSnackBarMessage(t('approved_request_success_message'));
      },
      onError: async () => {
        setSnackBarMessage(t('approved_request_error_message'));
      },
    }
  );

  const queryClient = useQueryClient();
  const { data, isLoading: isLoadingPackageType } = useQuery(['packageTypeDetail'], () => getPackageTypeById(row.id), {
    select: (data) => data?.data?.package_type as packagesTypes,
  });
  const memoizedResetQueries = useMemo(() => queryClient.resetQueries(['packageTypeDetail']), []);
  useEffect(() => {
    memoizedResetQueries;
  }, []);

  const { handleSubmit, values, setFieldValue, errors, validateForm } = useFormik({
    initialValues: {
      name: data?.name,
      price: data?.price,
    },
    onSubmit: (data: any) => {
      const objectData = {
        name: data?.name,
        price: data?.price,
      }
      updatePackageTypeMutate({ id: row?.id, req: objectData})
    },
    validationSchema: validate(t),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const childProps = {
    t,
    isLoadingPackageType,
    setFieldValue,
    errors,
    handleSubmit,
    values,
  };
  
  return <CreatePackageTypeForm {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  close: () => void;
  row: {
        id: number,
        name: string,
        price: number
      },
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
EditPackageFormContainer.propTypes = propTypes;

export default EditPackageFormContainer;
