import { Card, CardContent, CardHeader } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FadeIn } from '@/src/components/animations';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import DataTableStyled from '@/src/components/custom-data-table/CustomDataTable.styled';
import { DataTableForValetProps } from '../../types';

const CustomDataTableForValet = ({ title, data, columns }: DataTableForValetProps) => {
  const { t } = useTranslation();
  const pageSize = 2;
  return (
    <FadeIn>
      <Card
        sx={{
          marginBottom: '6px',
          marginLeft: '10px',
          boxShadow: 2,
          width: '100%',
          overflow: 'auto'
        }}
      >
        <CardHeader title={title} titleTypographyProps={{ variant: 'h6' }} />
        <CardContent sx={{ padding: '0px !important' }}>
          <DataTableStyled
            dense
            columns={columns}
            data={data}
            noDataComponent={<AlertNoData title={t('no_data_was_found')} />}
            theme="palierGridTheme"
            paginationTotalRows={data?.length}
            paginationPerPage={pageSize}
            pagination
            paginationComponentOptions={{ noRowsPerPage: true }}
          />
        </CardContent>
      </Card>
    </FadeIn>
  );
};

export default CustomDataTableForValet;
