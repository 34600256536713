import { axiosAuthenticationInstance } from '../instances/authInstance';
import {
  CreateAdminUserRequest,
  EditAdminClientUserRequest,
  EditAdminUserRequest,
  EditUserInfoRequest,
} from '../types/types';

// CREATE
export const createAdminUser = (req: CreateAdminUserRequest) =>
  axiosAuthenticationInstance.post(`/accounts/v1/admins`, req);
// EDIT
export const editAdminUser = (req: EditAdminUserRequest) =>
  axiosAuthenticationInstance.put(`/accounts/v1/admins/${req.id}`, req.body);

export const editAdminUserClient = (id: string, req: EditAdminClientUserRequest) =>
  axiosAuthenticationInstance.put(`/accounts/v1/admins/${id}/clients`, req);

export const editUserInfo = (req: EditUserInfoRequest) => axiosAuthenticationInstance.put(`accounts/v1/admins`, req);
// GET
export const getAdminGroups = () => axiosAuthenticationInstance.get(`/accounts/v1/admin-groups`);

export const getUserInfo = () => axiosAuthenticationInstance.get('accounts/v1/admins/profile');

export const getAdminInfo = (id: string) => axiosAuthenticationInstance.get(`/accounts/v1/admins/${id}`);

export const GetMailValidation = (email: string) =>
  axiosAuthenticationInstance.get(`/accounts/v1/admins/users/${email}`);
