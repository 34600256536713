import { Box, Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import AddIcon from '@mui/icons-material/Add';
import CustomLoader from '@/src/components/custom-loader';
import useSnackBar from '@/src/components/custom-snackbar/useSnackBar';
import { getArticlesAdmin } from '@/src/api/endpoints/community';
import {
  BoxCommunity,
  BoxHeaderInputs,
  CommunityHeader,
  CommunityPagseStylesDefault,
} from './styles/CommunityPage.styled';
import { LoaderContainer } from '@/src/components/loader-container/LoaderContainer';
import RefreshButton from '@/src/components/refresh-button/RefreshButton';
import AlertNoData from '@/src/components/custom-data-table/components/AlertNoData';
import { Community } from './types';
import CommunityCard from './community-card/CommunityCard';
import CommunityDetail from './community-detail/CommunityDetail';
import SearchInput from '@/src/components/search-input';
import PublicationsFilter from '@/src/components/publications-filter';

export const CommunityPage = () => {
  const queryClient = useQueryClient();
  const abortCont = new AbortController();

  const getArticlesData = async (abort?: any) => {
    const response = await getArticlesAdmin(abort ?? { signal: abortCont.signal });
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery(['posts'], getArticlesData, {
    refetchOnReconnect: false,
  });

  const [postData, setPostData] = useState<any>(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [refreshGrid, setRefreshGrid] = useState(false);
  const { SnackBar, setSnackBarMessage } = useSnackBar();
  const { t } = useTranslation();
  const resource = 'community';
  const isMobile = window.innerWidth < 955;

  const [idSelected, setIdSelected] = useState<number | null>(null);

  useEffect(() => {
    if (data && data.posts?.length > 0) {
      const orderedData = data.posts.sort((a: Community, b: Community) => b.creation_date - a.creation_date);
      setPostData(orderedData);
    }
  }, [data]);

  useEffect(() => {
    if (refreshGrid) {
      queryClient.resetQueries(['posts']);
      refetch();
      setRefreshGrid(false);
    }
  }, [refreshGrid]);

  useEffect(() => {
    queryClient.resetQueries(['posts']);
    abortCont.abort();
  }, []);

  const handleClickFiltersModal = () => {
    setOpenFilter(true);
  };

  return (
    <Box sx={!isLoading && postData?.length < 1 ? CommunityPagseStylesDefault : { height: 'auto' }}>
      <PublicationsFilter
        resource={resource}
        openModal={openFilter}
        setOpenModal={setOpenFilter}
        endpoint={getArticlesAdmin}
        setPostData={setPostData}
        isRestricted
      />
      <CommunityHeader pr={idSelected ? '20px' : '10px'}>
        <BoxCommunity>
          <Typography variant="h5" component="h1">
            {t('community_title', { ns: 'community' })}
          </Typography>
          <RefreshButton refresh={setRefreshGrid} disabled={isLoading} />
        </BoxCommunity>
        <BoxHeaderInputs>
          <SearchInput endpoint={getArticlesAdmin} disabled={false} resource={resource} setPostData={setPostData} />
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            size="small"
            color="primary"
            sx={{ width: { sm: 'auto', xs: 'auto' } }}
            onClick={handleClickFiltersModal}
          >
            {t('filter')}
          </Button>
        </BoxHeaderInputs>
      </CommunityHeader>
      <Grid container justifyContent="center">
        <Grid
          rowGap="20px"
          container
          item
          lg={idSelected ? 4 : 12}
          md={idSelected ? 4 : 12}
          sx={{
            maxHeight: `${isMobile ? 'auto' : '100%'}`,
            height: `${idSelected ? '850px' : '100%'}`,
            display: `${isMobile && idSelected && 'none'}`,
            overflow: 'auto',
            width: '100%',
          }}
        >
          {isLoading ? (
            <LoaderContainer>
              <CustomLoader />
            </LoaderContainer>
          ) : (
            ((!idSelected && isMobile) || !isMobile) &&
            postData?.map((item: Community) => (
              <Grid
                key={item.id}
                item
                lg={idSelected ? 12 : 4}
                md={idSelected ? 12 : 6}
                sm={12}
                xs={12}
                sx={{
                  paddingRight: '10px',
                }}
              >
                <CommunityCard item={item} setIdSelected={setIdSelected} />
              </Grid>
            ))
          )}
          {!isLoading && (!postData || postData.length < 1) && (
            <Box sx={{ margin: 'auto' }}>
              <AlertNoData title={t('no_data_was_found')} />
            </Box>
          )}
        </Grid>
        {idSelected && (
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <CommunityDetail
              idSelected={idSelected}
              setIdSelected={setIdSelected}
              setSnackBarMessage={setSnackBarMessage}
              resource={resource}
              refreshGrid={setRefreshGrid}
            />
          </Grid>
        )}
      </Grid>
      <SnackBar />
    </Box>
  );
};
