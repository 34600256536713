import { Box, Button, Grid } from '@mui/material';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import CustomInput from '@/src/components/forms/CustomInput';
import { FormikErrors } from 'formik';
import PropTypes from 'prop-types';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';

const CreatePackageTypeForm = (props: Props) => {
  const {t, values, handleSubmit, errors, setFieldValue } = props;

  return (
    <Box component="form" onSubmit={handleSubmit}>
        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
          <Grid item xs={12} sm={12} md={4} lg={12} sx={{ padding: '10px 0 0' }}>
            <CustomInput
              field="name"
              errors={errors}
              autoFocus
              label={t('modal_create_package_input_name', { ns: 'packages' })}
              placeholder={t('modal_create_package_input_name_placeholder', { ns: 'packages' })}
              setFieldValue={setFieldValue}
              values={values}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={12}>
          <Grid item xs={12} sm={12} md={4} lg={12}>
            <CustomInput
              inputType="number"
              field="price"
              errors={errors}
              autoFocus
              label={t('modal_create_package_input_price', { ns: 'packages' })}
              placeholder={t('modal_create_package_input_price_placeholder', { ns: 'packages' })}
              setFieldValue={setFieldValue}
              values={values}
              required
            />
          </Grid>
          </Grid>
          <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
            <Button
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              type="submit"
              variant="contained"
              size="small"
            >
              {t('create')}
            </Button>
          </Grid>
        </Grid>
    </Box>
  );
};

const propTypes = {
  t: PropTypes.func.isRequired,
};

interface ExtraProps{
  values: any,
  handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void,
  errors: FormikErrors<any>,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<any>>,
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
CreatePackageTypeForm.propTypes = propTypes;

export default CreatePackageTypeForm;
