import getEnvVariables from '../utilities/helpers/getEnvVariables';
import EventBackgroundPattern from './common/logos/eventBackgroundPattern.svg';
import pdfIconImage from './common/logos/pdfFile.svg';
import { grit } from './grit';
import { panorama } from './panorama';
import { beachwalk } from './beachwalk';
import { ora } from './ora';
import { nexo } from './nexo';
import { rider } from './rider';
import { useMemo, useContext } from 'react';
import { AuthContext } from '../context/auth.context';

const { VITE_TENANT_NAME } = getEnvVariables();

export const useExportAssets = () => {
  const { clientStyle } = useContext(AuthContext);
  const formatedClientStyle = JSON.parse(clientStyle);

  const formatedGrit = {
    headLogo: grit?.headLogo,
    mainLogo: formatedClientStyle?.main_logo_url || grit?.mainLogo,
    whiteLogo: formatedClientStyle?.main_logo_url || grit?.whiteLogo,
    backgroundPic: grit?.backgroundPic,
  }

  const objAssets: {[key: string]: any } = useMemo(() => {
    return {
      ora,
      grit: formatedGrit,
      panorama,
      beachwalk,
      nexo,
      rider,
    };
  }, [clientStyle]);

  const client = objAssets[VITE_TENANT_NAME];

  const { backgroundPic, mainLogo, whiteLogo, headLogo } = client || {};

  const EventBackgroundPatternImage = EventBackgroundPattern;
  const pdfIcon = pdfIconImage;

  return { backgroundPic, mainLogo, whiteLogo, headLogo, EventBackgroundPatternImage, pdfIcon };
};