import { axiosAuthenticationInstance } from '../instances/authInstance';
import { BodyChangeState, ConfirmPackagesRequest, CreatePackageRequest, CreatePackageTypeRequest, LoadRackPackagesBody, UpdatePackageTypeRequest } from '../types/types';

//get
export const getPackagesByPropertyCodeAndCustomerCode = (req: { propertyCode: string; customerCode: string }) =>
  axiosAuthenticationInstance.get(
    `packaging/v1/packages/validate?propertyCode=${req.propertyCode}&customerCode=${req.customerCode}`,
  );

  export const getPackageById = (id: number) => axiosAuthenticationInstance.get(`packaging/v1/packages/${id}`);

  export const loadPackage = () => axiosAuthenticationInstance.get(`packaging/v1/carriers`);

  export const getPackagesStates = () => axiosAuthenticationInstance.get(`packaging/v1/package-states`);

  export const getValetById = (id: number) => axiosAuthenticationInstance.get(`packaging/v1/door-to-door/requests/${id}`)

  export const getPackagesTypes = () => axiosAuthenticationInstance.get(`packaging/v1/package-types`);

  export const getPackageTypeById = (id: number) => axiosAuthenticationInstance.get(`packaging/v1/package-types/${id}`);

// post
export const createPackage = (req: CreatePackageRequest) =>
  axiosAuthenticationInstance.post(`packaging/v1/packages`, req);

export const createPackageType = (req: CreatePackageTypeRequest) =>
  axiosAuthenticationInstance.post(`packaging/v1/package-types`, req);

export const confirmPackages = (req: ConfirmPackagesRequest) =>
  axiosAuthenticationInstance.post(`packaging/v1/packages/confirm`, req);

export const sendToPrint = (id: number) => axiosAuthenticationInstance.post(`packaging/v1/packages/${id}/print`);

// put
export const loadRackPackage = (req: LoadRackPackagesBody) =>
  axiosAuthenticationInstance.put(`packaging/v1/packages/${req.package_code}/location`, {
    location: req.rack_code,
  });

export const updatePackageType = (id: number, req: UpdatePackageTypeRequest) =>
  axiosAuthenticationInstance.put(`packaging/v1/package-types/${id}`, req);

export const cancelPackageDelivery = (id: number) => 
  axiosAuthenticationInstance.put(`packaging/v1/door-to-door/requests/${id}/cancel`);

export const ChangeDeliveryStatePackage = async (body: BodyChangeState) => 
  axiosAuthenticationInstance.put(
    `packaging/v1/door-to-door/requests/${body?.id}/deliver`, {
      delivery_image_url: body?.valetImage
    }
  );

// delete
export const deletePackage = (id: number) => axiosAuthenticationInstance.delete(`packaging/v1/packages/${id}`);

export const deletePackageType = (id: number) => axiosAuthenticationInstance.delete(`packaging/v1/package-types/${id}`);

