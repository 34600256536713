import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { loadPackage, getPackagesTypes } from '@/src/api/endpoints/packages';
import { Carrier, LoadPackageModalProps, packagesTypes } from '../../types';
import LoadPackageFormStep1 from './load-package-step-1/LoadPackageStep1';
import LoadPackageFormStep2 from './load-package-step-2/LoadPackageStep2';
import LoadPackageFormStep3 from './load-package-step-3/LoadPackageStep3';
import LoadPackageFormStep4 from './load-package-step-4/LoadPackageStep4';
import { validate } from './LoadPackageForm.validate';
import { getProperties } from '@/src/api/endpoints/residents';

type TStepsOptions = {
  [key: string]: any;
};

const LoadPackageFormContainer = ({ close, setSnackBarMessage }: LoadPackageModalProps) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const { t } = useTranslation();
  const { data: carriers, isLoading: isLoadingGetCarriers } = useQuery(['loadPackage'], () => loadPackage(), {
    select: (data) => data?.data.carriers,
  });
  const { data: packagesTypes, isLoading: isLoadingPackagesTypes} = useQuery(['loadPackageTypes'], () => getPackagesTypes(), {
    select: (data) => data?.data.package_types,
  })
  const defaultCarrier = carriers?.find((carrier: Carrier) => carrier.code === 'other' || carrier.code === 'otro');
  const { data: response } = useQuery(['getProperties'], () => getProperties());

  const { handleSubmit, values, setFieldValue, errors, validateForm } = useFormik({
    initialValues: {
      carrier_id: defaultCarrier?.id,
      carrier_name: defaultCarrier?.name,
      package_type: null,
      property_code: '',
      carrier_code: '',
      is_expirable: false,
    },
    onSubmit: () => {},
    validationSchema: validate(t, currentStep),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const childProps = {
    setCurrentStep,
    close,
    setSnackBarMessage,
    currentStep,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    validateForm,
    t,
    isLoadingGetCarriers,
    carriers,
    packagesTypes,
    propertiesList: response?.data.properties,
  };
  const steps: TStepsOptions = {
    1: LoadPackageFormStep1,
    2: LoadPackageFormStep2,
    3: LoadPackageFormStep3,
    4: LoadPackageFormStep4,
  };
  const CurrentComponent = steps[currentStep];
  return <CurrentComponent {...childProps} />;
};

export default LoadPackageFormContainer;
