import { useTranslation } from 'react-i18next';
import CustomDataTable from '@/src/components/custom-data-table/CustomDataTable';
import { useColumns } from './useColumns';

export const PackagesTypesPage = () => {
  const { t } = useTranslation();
  const { columns, actionColumns, headerActions } = useColumns();

  return (
    <CustomDataTable
      title={t('packages_types_page_title', { ns: 'packages' })}
      columns={columns}
      resource="package_types"
      pathApi="packaging/v1/package-types"
      listName="package_types"
      extraActionsInHeader={headerActions}
      canSearch
      canExport
      defaultSortFieldId="id"
      defaultSortAsc={false}
      customActionColumns={actionColumns}
    />
  );
};
