import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import UserFormEdit2 from './administrators-modal/administrator-formEdit';
import UserForm2 from './administrators-modal/administrator-form';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { Row } from './types';
import { getLanguage } from '@/src/utilities/storage';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEs,
  getFormattedDateFullYearEn,
} from '@/src/utilities/helpers/dateParser';
import AdministratorDetail from './administrators-modal/administrator-detail';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const rol = localStorage.getItem('user');
  const user = [];

  if (rol) user?.push(JSON.parse(rol as string));

  const isSuperAdmin = user?.find((us: { id: number; code: string }) => us?.code === 'SuperAdmin');

  const columns: CustomDataTableColumnsProps<Row>[] = [
    {
      id: 'id',
      name: t('user_id', { ns: 'users' }),
      selector: (row) => row.internal_id,
      sortField: 'internal_id',
      sortable: true,
      filters: [
        {
          id: 'internal_id',
          query: 'InternalId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'name',
      name: t('admin_users_list_name_column', { ns: 'users' }),
      selector: (row) => row.name,
      sortField: 'name',
      sortable: true,
      right: true,
      filters: [
        {
          id: 'name',
          query: 'Name',
          type: 'contains',
        },
      ],
    },
    {
      id: 'surname',
      name: t('admin_users_list_surname_column', { ns: 'users' }),
      selector: (row) => row.surname,
      sortField: 'surname',
      sortable: true,
      filters: [
        {
          id: 'surname',
          query: 'Surname',
          type: 'contains',
        },
      ],
    },
    {
      id: 'email',
      name: t('admin_users_list_email_column', { ns: 'users' }),
      selector: (row) => row.email,
      sortField: 'email',
      sortable: true,
      filters: [
        {
          id: 'email',
          query: 'Email',
          type: 'contains',
        },
      ],
    },
    {
      id: 'phone_number',
      name: t('admin_users_list_phone_number_column', { ns: 'users' }),
      selector: (row) => row.full_phone_number,
      sortField: 'phone_number',
      sortable: true,
      right: true,
      filters: [
        {
          id: 'full-phone-number',
          query: 'FullPhoneNumber',
          type: 'contains',
        },
      ],
    },
    {
      id: 'creation_date',
      name: t('creation_date', { ns: 'users' }),
      selector: (row) =>
        row.creation_date
          ? getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row.creation_date)
          : '-',
      sortField: 'creation_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'creation-date',
          query: 'CreationDate',
          type: 'date',
        },
      ],
    },
  ];

  const actionColumns: ActionColumn[] = [
    ...(isSuperAdmin
      ? [
          {
            id: 'detail',
            icon: (props: any) => <VisibilityRoundedIcon sx={props.sx} />,
            component: (props: any) => <AdministratorDetail {...props} />,
            width: { xs: '90%', sm: '80%', lg: '60%' },
          },
        ]
      : []),
    {
      id: 'edit',
      icon: (props) => <EditIcon sx={props.sx} />,
      component: (props: any) => <UserFormEdit2 {...props} />,
      width: { xs: '90%', sm: '80%' },
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'admin_accounts',
      component: () => (
        <CustomTableAction
          modalTitle="admin_accounts"
          component={(props: any) => <UserForm2 {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '45%', sm: '40%' }}
        />
      ),
    },
  ];

  return { columns, actionColumns, headerActions };
};
