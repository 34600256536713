import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import { Carrier, LoadPackageModalProps, Package } from '../../../types';
import { isValidDigit } from '@/src/utilities/helpers/stringsHelper';
import { CircularSpinner } from '@/src/components/circular-spinner/CircularSpinner';
import CustomInput from '@/src/components/forms/CustomInput';

const LoadPackageFormStep1 = ({
  setCurrentStep,
  t,
  values,
  handleSubmit,
  errors,
  validateForm,
  carriers,
  packagesTypes,
  setFieldValue,
  isLoadingGetCarriers: isLoadingForm,
}: LoadPackageModalProps | any) => {
  const [barcode, setBarcode] = useState('');

  const ref = useRef<any>(null);

  const onKeyDown = (keyEvent: any) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const onKeyPressed = (e: any) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      if (document.activeElement !== ref.current) {
        setFieldValue('carrier_code', barcode);
      }
      setBarcode('');
    } else if (isValidDigit(keyCode)) {
      setBarcode(barcode + e.key);
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeyPressed, false);

    return () => {
      document.removeEventListener('keydown', onKeyPressed, false);
    };
  });
  useEffect(() => {
    setBarcode(values.carrier_code);
  }, [values.carrier_code]);

  return (
    <Box component="form" onSubmit={handleSubmit} onKeyDown={onKeyDown}>
      {isLoadingForm ? (
        <CircularSpinner />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2" sx={{ padding: '10px 0 0' }}>
              {t('modal_load_package_sub_title', { ns: 'packages' })}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={12}>
            <CustomInput
              field="carrier_code"
              errors={errors}
              autoFocus
              label={t('modal_load_package_input_carrier_code', { ns: 'packages' })}
              placeholder={t('modal_load_package_input_carrier_code_placeholder', { ns: 'packages' })}
              setFieldValue={setFieldValue}
              values={values}
              inputProps={{
                endAdornment: (
                  <HorizontalSplitIcon
                    sx={{ color: 'rgba(0, 0, 0, 0.6)', position: 'absolute', right: 8, transform: 'rotateZ(90deg)' }}
                  />
                ),
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={12}>
          <Autocomplete
              id="carriers"
              size="small"
              options={carriers}
              autoHighlight
              clearOnEscape
              onChange={(e: any, value: any) => {
                setFieldValue('carrier_id', value?.id);
                setFieldValue('carrier_name', value?.name);
              }}
              value={carriers.find((carrier: Carrier) => carrier.id === values?.carrier_id) || null}
              getOptionLabel={(option) => option.name}
              renderOption={(renderOptionProps, option) => (
                <Box component="li" key={option.id} {...renderOptionProps}>
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={`${t('modal_load_package_input_carrier_id', { ns: 'packages' })} * `}
                  error={errors.carrier_name !== undefined}
                  helperText={errors.carrier_name?.toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={12}>
          <Autocomplete
            id="type"
            size="small"
            options={packagesTypes || []}
            autoHighlight
            clearOnEscape
            onChange={(e: any, value: any) => {
              setFieldValue('package_type', value);
            }}
            value={packagesTypes?.find((packageType: Package) => packageType?.id === values?.package_type?.id) || null}
            getOptionLabel={(option) => `${option?.name} - $${option?.price}`}
            renderOption={(renderOptionProps, option) => (
              <Box component="li" key={option.id} {...renderOptionProps}>
                {t(`${option?.name}`, { ns: 'packages' })} - ${option?.price}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${t('modal_load_package_input_package_size_type', { ns: 'packages' })} * `}
                error={errors?.package_type !== undefined}
                helperText={errors?.package_type?.toString()}
              />
            )}
          />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={12}>
          </Grid>
          <Grid xs={12} md={4} lg={12} item>
            <FormControlLabel
              onChange={() => {
                setFieldValue('is_expirable', !values.is_expirable);
              }}
              control={<Checkbox checked={values.is_expirable} />}
              label={t('load_package_step_1_is_expirable', { ns: 'packages' })}
            />
          </Grid>
          <Grid container item xs={12} sm={12} md={12} justifyContent="flex-end">
            <Button
              sx={{ width: { xs: '100%', sm: 'auto' } }}
              type="button"
              variant="contained"
              onClick={() => {
                validateForm().then((formErrors: any) => {
                  if (Object.entries(formErrors).length === 0) setCurrentStep(2);
                });
              }}
              size="small"
            >
              {t('next')}
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default LoadPackageFormStep1;
