import { useContext } from 'react';
import { createTheme } from '@mui/material';
import { red } from '@mui/material/colors';

import AvenirNextBold from '../../assets/fonts/AvenirNext/AvenirNext-Bold.ttf';
import AvenirNextItalic from '../../assets/fonts/AvenirNext/AvenirNext-Italic.ttf';
import AvenirNextMedium from '../../assets/fonts/AvenirNext/AvenirNext-Medium.ttf';
import AvenirNextRegular from '../../assets/fonts/AvenirNext/AvenirNext-Regular.ttf';
import AvenirNextSemiBold from '../../assets/fonts/AvenirNext/AvenirNext-SemiBold.ttf';
import { AuthContext } from '@/src/context/auth.context';

const useBaseLightGritTheme = () => {
  const { clientStyle } = useContext(AuthContext);

  const baseTheme = createTheme({
    palette: {
      error: {
        main: red.A400,
      },
      mode: 'light',
      primary: {
        main: JSON.parse(clientStyle)?.primary_color || JSON.parse(clientStyle)?.primary_color || '#001441',
      },
      secondary: {
        main: JSON.parse(clientStyle)?.secondary_color || JSON.parse(clientStyle)?.secondary_color || '#A68B5C',
      },
      background: {
        default: '#f3f3f4',
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none',
            },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
            @font-face {
              font-family: "AvenirNext";
              font-style: italic;
              font-weight: 400;
              font-display: "swap";
              src: url("${AvenirNextItalic}") format("truetype");
            }
            @font-face {
              font-family: "AvenirNext";
              font-style: normal;
              font-weight: 500;
              font-display: "swap";
              src: url("${AvenirNextMedium}") format("truetype");
            }
            @font-face {
              font-family: "AvenirNext";
              font-style: normal;
              font-weight: 400;
              font-display: "swap";
              src: url("${AvenirNextRegular}") format("truetype");
            }
            @font-face {
              font-family: "AvenirNext";
              font-style: normal;
              font-weight: 600;
              font-display: "swap";
              src: url("${AvenirNextSemiBold}") format("truetype");
            }
            @font-face {
              font-family: "AvenirNext";
              font-style: normal;
              font-weight: 700;
              font-display: "swap";
              src: url("${AvenirNextBold}") format("truetype");
            }
          `,
      },
    },
    typography: {
      fontFamily: ['AvenirNext', 'Roboto', 'Helvetica', 'Arial', 'serif'].join(','),
    },
  });

  return baseTheme;
};

export default useBaseLightGritTheme;
