import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import {
  createAdminUser,
  editAdminUserClient,
  getAdminGroups,
  GetMailValidation,
} from '@/src/api/endpoints/admin-users';
import UserForm2 from './AdministratorForm';
import { validate } from './AdministratorForm.validate';
import { Activities, AdminUsers, BodyCreateAdmin, BodyEditAdmin } from '../../types';
import { getAmenities } from '@/src/api/endpoints/bookings';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import getEnvVariables from '@/src/utilities/helpers/getEnvVariables';

const emptyInitialValues = {
  adminGroups: [],
  confirmPassword: '',
  email: '',
  name: '',
  notes: '',
  password: '',
  phoneNumber: '',
  countryCode: '',
  countryIsoCode: '',
  surname: '',
  activityIds: [],
  user_id: '',
};

const getInitialValues = (userData: any) => ({
  ...emptyInitialValues,
  adminGroups: userData?.groups ?? [],
  email: userData?.email ?? '',
  name: userData?.name ?? '',
  notes: userData?.notes ?? '',
  phoneNumber: userData?.phone_number ?? '',
  countryCode: userData?.country_code ?? '',
  countryIsoCode: userData?.country_iso_code ?? '',
  surname: userData?.surname ?? '',
  activityIds: userData?.booking_activities ?? [],
  user_id: userData?.user_id ?? '',
});

const AdministratorFormContainer = (props: Props) => {
  const { close, setSnackBarMessage } = props;
  const { t } = useTranslation('users');
  const { VITE_TENANT_CHILDREN } = getEnvVariables();
  const [showEmailSection, setShowEmailSection] = useState<boolean>(JSON.parse(VITE_TENANT_CHILDREN));
  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<{ code: string; message: string; property_name: string } | null>(
    null,
  );
  const [adminAlreadyExist, setAdminAlreadyExist] = useState(false);

  const { mutate, isLoading } = useMutation(createAdminUser, {
    onError: async (e: any) => {
      setErrorMessage(e.response.data.error_messages[0]);
    },
    onSuccess: async () => {
      close();
      setSnackBarMessage(t('successful_user_creation_snackbar_text'));
    },
  });

  const { mutate: mutateAdminClient } = useMutation(
    ({ id, req }: { id: string; req: any }) => editAdminUserClient(id, req),
    {
      onError: async (e: any) => {
        setErrorMessage(e.response.data.error_messages[0]);
      },
      onSuccess: async () => {
        close();
        setSnackBarMessage(t('successful_user_creation_snackbar_text'));
      },
    },
  );

  const onSubmit = (data: FormikValues) => {
    if (adminAlreadyExist) {
      const body: BodyEditAdmin = {
        notes: data.notes,
        group_ids: data.adminGroups?.map((x: { id: number; name: string }) => x.id),
        activity_ids: data.activityIds?.map((x: { id: number; name: string }) => x.id),
      };
      mutateAdminClient({ id: data?.user_id, req: body });
    } else {
      const body: BodyCreateAdmin = {
        email: data.email.trim(),
        group_ids: data.adminGroups?.map((x: { id: number; name: string }) => x.id),
        name: data.name,
        notes: data.notes,
        password: data.password,
        phone_number: data.phoneNumber,
        surname: data.surname,
        country_code: data.countryCode,
        country_iso_code: data.countryIsoCode,
        activity_ids: data.activityIds?.map((x: { id: number; name: string }) => x.id),
      };
      mutate(body);
    }
  };

  const { handleSubmit, values, setFieldValue, errors, resetForm } = useFormik({
    initialValues: emptyInitialValues,
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate({ t, adminAlreadyExist }),
  });

  const { data: response, isLoading: loading } = useQuery(['getAdminGroups'], () => getAdminGroups());
  const adminUserGroups: Array<AdminUsers> | undefined | null = response?.data.groups;
  const { data: amenitiesData, isLoading: getAmenitiesLoading } = useQuery(['activities'], () =>
    getAmenities({ page: 0 }),
  );
  const amenitiesList: { activities: Array<Activities> | undefined | null; totalCount: number } = amenitiesData?.data;

  const { mutate: mutateGetAdminClient } = useMutation(({ mail }: { mail: string }) => GetMailValidation(mail), {
    onError: async () => {
      setAdminAlreadyExist(false);
      resetForm({
        values: {
          ...getInitialValues(emptyInitialValues),
          email: userEmail,
        },
      });
      await setShowEmailSection(false);
    },
    onSuccess: async (data: any) => {
      setAdminAlreadyExist(true);
      resetForm({
        values: {
          ...getInitialValues(data?.data.admin),
        },
      });
      await setShowEmailSection(false);
    },
  });

  const getUserData = async () => {
    await mutateGetAdminClient({ mail: values?.email });
    await setUserEmail(values?.email);
  };

  const childProps = {
    t,
    adminAlreadyExist,
    showEmailSection,
    getUserData,
    adminUserGroups,
    amenitiesList,
    getAmenitiesLoading,
    handleSubmit,
    values,
    setFieldValue,
    errors,
    loading,
    errorMessage,
    isSubmitLoading: isLoading,
    showAmenitiesSelector:
      (values?.adminGroups?.find((rol: AdminUsers) => rol.code === 'Bookings') &&
        !values?.adminGroups?.find((rol: AdminUsers) => rol.code === 'Admins')) ??
      false,
  };

  return <UserForm2 {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  close: () => void;
  setSnackBarMessage: (msj: string, sever?: string) => void;
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
AdministratorFormContainer.propTypes = propTypes;

export default AdministratorFormContainer;
