import { InferPropsExtended } from "@/src/utilities/helpers/proptypesHelper";
import BuildingSelector from "./BuildingSelector";
import PropTypes from 'prop-types';
import { Buildingvalues, Tower } from "@/src/pages/building-swap/types";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import getEnvVariables from "@/src/utilities/helpers/getEnvVariables";
import { useGetChildren } from "@/src/hooks/common/useGetChildren";
import { AuthContext } from "@/src/context/auth.context";

export const BuildingSelectorContainer = (props: Props) => {

    const { t, values, setValues, setFirstGroupLogin, setSecondGroupLogin, setThirdGroupLogin } = props;
    // variables
    const { VITE_TENANT_ID } = getEnvVariables();
    // building used and the array of buildings
    const { buildingsUsed: buildingsUsedData } = useContext(AuthContext);
    const buildingsUsed = buildingsUsedData || null;
    // towers group
      const [firstTowersGroup, setFirstTowersGroup] = useState<Tower[]>(buildingsUsed?.firstBuildingsGroup || []);
      const [secondTowersGroup, setSecondTowersGroup] = useState<Tower[]>(buildingsUsed?.secondBuildingsGroup || []);
      const [thirdTowersGroup, setThirdTowersGroup] = useState<Tower[]>(buildingsUsed?.thirdBuildingsGroup || []);
    // code
    const [currentTenantId, setCurrentTenantId] = useState<string | null>(VITE_TENANT_ID);
    // hooks
    const { childrenList, refetch: refetchChildren } = useGetChildren(currentTenantId as string);
    // errors
  const errors: Record<string, any> = {};

      const handleRefetchTower = async (tenantToRefetch: string, seterFunction: Dispatch<SetStateAction<Tower[]>>, seterOnLoginFunction?: Dispatch<SetStateAction<Tower[]>> | null) => {
        await setCurrentTenantId(tenantToRefetch);
        const { data } = await refetchChildren();
        await seterFunction(data?.children);
        if(seterOnLoginFunction)
          await seterOnLoginFunction(data?.children);
      };
    
      const handleAssignValues = (
        firstTowerValue: any,
        secondTowerValue: any,
        thirdTowerValue: any,
        tenantValue: string,
      ) => {
        setValues({
          firstTowerInstance: firstTowerValue,
          secondTowerInstance: secondTowerValue,
          thirdTowerInstance: thirdTowerValue,
          codeTenant: tenantValue,
        });
      };
    
      const handleFirstTowerChange = (event: any, value: any) => {
        if (!value?.has_children) {
          handleAssignValues(value, null, null, value?.client_tenant_code);
          setSecondTowersGroup([]);
          if(setSecondGroupLogin)
            setSecondGroupLogin([]);
          setThirdTowersGroup([]);
          if(setThirdGroupLogin)
            setThirdGroupLogin([]);
        } else {
          if(setSecondGroupLogin){
            handleRefetchTower(value?.client_tenant_code, setSecondTowersGroup, setSecondGroupLogin);
          } else {
            handleRefetchTower(value?.client_tenant_code, setSecondTowersGroup);
          }
          handleAssignValues(value, null, null, value?.client_tenant_code);
          setThirdTowersGroup([]);
          if(setThirdGroupLogin)
            setThirdGroupLogin([]);
        }
      };
    
      const handleSecondTowerChange = (event: any, value: any) => {
        if (!value?.has_children) {
          handleAssignValues(values?.firstTowerInstance, value, null, value?.client_tenant_code);
          setThirdTowersGroup([]);
          if(setThirdGroupLogin)
            setThirdGroupLogin([]);
        } else {
          if(setThirdGroupLogin){
            handleRefetchTower(value?.client_tenant_code, setThirdTowersGroup);
          } else {
            handleRefetchTower(value?.client_tenant_code, setThirdTowersGroup, setThirdGroupLogin);
          }
          handleAssignValues(values?.firstTowerInstance, value, null, value?.client_tenant_code);
        }
      };
    
      const handleThirdTowerChange = (event: any, value: any) => {
        handleAssignValues(values?.firstTowerInstance, values?.secondTowerInstance, value, value?.client_tenant_code);
      };
    
      useEffect(() => {
        if(firstTowersGroup === undefined || firstTowersGroup?.length === 0){
          setFirstTowersGroup(childrenList?.children);
          if(setFirstGroupLogin)
            setFirstGroupLogin(childrenList?.children);
        }
          
      }, [childrenList])

  const childProps = {
    ...props,
    t,
    values,
    firstTowersGroup,
    secondTowersGroup,
    thirdTowersGroup,
    errors,
    handleFirstTowerChange,
    handleSecondTowerChange,
    handleThirdTowerChange,
  };

  return <BuildingSelector {...childProps} />;
};

const propTypes = {
    t: PropTypes.func.isRequired,
  };
  
  interface ExtraProps {
    values: Buildingvalues;
    setValues: React.Dispatch<React.SetStateAction<Buildingvalues>>;
    setFirstGroupLogin?: React.Dispatch<React.SetStateAction<Tower[]>> | null ;
    setSecondGroupLogin?: React.Dispatch<React.SetStateAction<Tower[]>> | null;
    setThirdGroupLogin?: React.Dispatch<React.SetStateAction<Tower[]>> | null;
  }
  
  interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
  
  BuildingSelectorContainer.propTypes = propTypes;
  
  export default BuildingSelectorContainer;
  