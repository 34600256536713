import { useTranslation } from 'react-i18next';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { CancellationsColumns } from './types';
import {
  getFormattedDateByLanguague,
  getFormattedDateFullYearEn,
  getFormattedDateFullYearEs,
} from '@/src/utilities/helpers/dateParser';
import { getLanguage } from '@/src/utilities/storage';
import useApartmentsFilters from '@/src/hooks/filters/apartments.hooks';
import useAgendasFilters from '@/src/hooks/filters/agendas.hooks';
import useActivitiesFilters from '@/src/hooks/filters/activities.hooks';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];
  const { apartments } = useApartmentsFilters();
  const { agendaList } = useAgendasFilters();
  const { activities } = useActivitiesFilters();
  const columns: CustomDataTableColumnsProps<CancellationsColumns>[] = [
    {
      id: 'id',
      name: t('id'),
      selector: (row: CancellationsColumns) => row?.id,
      sortField: 'id',
      sortable: true,
      right: true,
      maxWidth: '1%',
      filters: [
        {
          id: 'id',
          query: 'BookingId',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'activity',
      name: t('reservations_page_activity', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) => row?.activity?.name,
      sortField: 'activity',
      sortable: true,
      filters: activities,
      width: '120px',
    },
    {
      id: 'agenda',
      name: t('reservations_page_agenda', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) => row?.agenda?.name,
      sortField: 'agenda',
      sortable: true,
      filters: agendaList,
      width: '120px',
    },
    {
      id: 'customer_full_name',
      name: t('reservations_page_name_surname', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) => row?.customer?.full_name,
      sortField: 'customer_full_name',
      sortable: true,
      filters: [
        {
          id: 'customer_name',
          query: 'CustomerFullName',
          type: 'contains',
        },
      ],
    },
    {
      id: 'property_code',
      name: t('cancellations_page_functional_unit', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) => row?.property?.code,
      sortField: 'property_code',
      sortable: true,
      right: true,
      filters: apartments,
      width: '100px',
    },
    {
      id: 'booking_date',
      name: t('cancellations_page_reservation_date', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) =>
        getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row?.date),
      sortField: 'booking_date',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'booking_date',
          query: 'BookingDate',
          type: 'date',
        },
      ],
    },
    {
      id: 'selected_time_from',
      name: t('cancellations_page_selected_time_from', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) => row?.selected_time?.substring(0, 7),
      sortField: 'selected_time',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'booking_time_from',
          query: 'BookingTimeFrom',
          type: 'time',
        },
      ],
    },
    {
      id: 'selected_time_to',
      name: t('cancellations_page_selected_time_to', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) => row?.selected_time?.substring(9),
      sortField: 'selected_time',
      sortable: true,
      center: true,
      filters: [
        {
          id: 'booking_time_to',
          query: 'BookingTimeTo',
          type: 'time',
        },
      ],
    },
    {
      id: 'cancellation_date',
      name: t('cancellations_page_cancellation_date', { ns: 'bookings' }),
      selector: (row: CancellationsColumns) =>
        getFormattedDateByLanguague(lang, getFormattedDateFullYearEs, getFormattedDateFullYearEn, row?.cancelation_date),
      sortField: 'cancellation_date',
      sortable: true,
      center: true,
      minWidth: '170px',
      filters: [
        {
          id: 'booking_cancelation_date',
          query: 'BookingCancellationDate',
          type: 'date',
        },
      ],
    },
  ];

  return {
    columns,
  };
};
