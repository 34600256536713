import { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react';
import { User } from '../hooks/auth/types';
import { getMenus } from '../utilities/storage';
import { Menu, MenuItem } from '../utilities/helpers/commonTypes';

type IntialState = {
  user: User | null;
  setUser: Dispatch<SetStateAction<User | null>>;
  menus: Menu;
  setMenus: (value: MenuItem[] | null) => void;
};

export const UserContext = createContext({} as IntialState);

const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(JSON.parse(String(localStorage.getItem('user-old'))) as User);
  const [menus, setMenus] = useState(getMenus());

  const memoedValue = useMemo(() => {
    const value = {
      user,
      setUser,
      menus,
      setMenus,
    };
    return value;
  }, [user, setUser, menus, setMenus]);

  return <UserContext.Provider value={memoedValue}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
