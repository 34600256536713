import { useQuery } from '@tanstack/react-query';
import { getChildren } from '@/src/api/endpoints/master';

export const useGetChildren = (clientTenantCode: string) => {
  const {
    data: childrenList,
    isLoading,
    refetch,
  } = useQuery(['children'], () => getChildren(clientTenantCode), {
    select: (data) => data?.data?.client,
  });

  return { childrenList, isLoading, refetch };
};
