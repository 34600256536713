import { axiosAuthenticationInstance } from '../instances/authInstance';
import { CreateCategoryRequest, EditCategoryRequest } from '../types/types';

export const getsLanguages = () => axiosAuthenticationInstance.get('/master/v1/languages');
export const getChildren = (clientTenantCode: string) =>
  axiosAuthenticationInstance.get(`master/v1/clients/${clientTenantCode}/children`);
export const getStyles = (clientTenantCode: string) =>
  axiosAuthenticationInstance.get(`master/v1/clients/${clientTenantCode}/styles`);

export const createCategory = (req: CreateCategoryRequest) =>
  axiosAuthenticationInstance.post('/support/v1/categories', req);

export const getsCategory = (id: number) => axiosAuthenticationInstance.get(`/support/v1/categories/${id}`);
export const deleteCategory = (id: number) => axiosAuthenticationInstance.delete(`/support/v1/categories/${id}`);

export const editCategory = (req: EditCategoryRequest) =>
  axiosAuthenticationInstance.put(`/support/v1/categories/${req.id}`, {
    category_translations: req.category_translations,
  });

export const getResidencyTypes = () => axiosAuthenticationInstance.get('/master/v1/residency-types');

export const getCountries = () => axiosAuthenticationInstance.get(`/master/v1/countries`);
export const getStates = (id: number) => axiosAuthenticationInstance.get(`/master/v1/countries/${id}/states`);
export const getCities = (id: number) => axiosAuthenticationInstance.get(`/master/v1/states/${id}/cities`);
