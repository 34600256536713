import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Navigate, useSearchParams } from 'react-router-dom';
import { requestGuestVehicle } from '@/src/api/endpoints/parking';
import { CarRecall } from './CarRecall';
import { useGetStyles } from '../../building-swap/hook/useGetStyles';

export const CarRecallPage = () => {
  const [searchParams] = useSearchParams();
  const codeParam = searchParams.get('code');
  const tenantID = searchParams.get('tenant_id');
  const [requestResponse, setRequestResponse] = useState<null | boolean>(null);
  const { styles } = useGetStyles(tenantID as string);

  const { mutate, isLoading } = useMutation(requestGuestVehicle, {
    onSuccess: () => {
      setRequestResponse(true);
    },
    onError: () => {
      setRequestResponse(false);
    },
  });
  const handleRequestGuestVehicle = () => mutate(codeParam || '');

  const setViteTenantId = async() => {
    await localStorage.setItem('vite_tenant_id', tenantID as string);
  };

  useEffect(() => {
    if(tenantID)
      setViteTenantId();
  }, [])

  const childProps = {
    requestResponse,
    isLoading,
    handleRequestGuestVehicle,
    styles,
  };

  if (codeParam) return <CarRecall {...childProps} />;

  return <Navigate to="/auth/login" replace />;
};
