import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { LoadPackageModalProps } from '../../types';
import { validate } from './CreatePackageTypeForm.validate';
import CreatePackageTypeForm from './CreatePackageType';
import { createPackageType } from '@/src/api/endpoints/packages';
import { useMutation } from '@tanstack/react-query';

const LoadPackageFormContainer = ({ close, setSnackBarMessage }: LoadPackageModalProps) => {
  const { t } = useTranslation();
  const { mutate: createPackageMutate } = useMutation(createPackageType);

  const { handleSubmit, values, setFieldValue, errors, validateForm } = useFormik({
    initialValues: {
      name: '',
      price: '',
    },
    onSubmit: (data: any) => {
      const objectData = {
        name: data?.name,
        price: data?.price,
      }
      createPackageMutate(objectData, {
        onSuccess: async() => {
          setSnackBarMessage(t('approved_request_success_message'));
        }, onError : async() => {
          setSnackBarMessage(t('approved_request_error_message'));
        }
      })
    },
    validationSchema: validate(t),
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const childProps = {
    t,
    values,
    handleSubmit,
    errors,
    setFieldValue,
  };
  
  return <CreatePackageTypeForm {...childProps} />;
};

export default LoadPackageFormContainer;
