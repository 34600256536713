import { Box, Button, Divider, Grid, IconButton, Typography, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ChangeDeliveryStatePackage, getValetById } from '@/src/api/endpoints/packages';
import { getLanguage } from '@/src/utilities/storage';
import { TLanguagesOptions } from '@/src/utilities/helpers/commonTypes';
import { ErrorText } from '@/src/components/error/error-text/ErrorText';
import CustomCardMedia from '@/src/components/custom-card-media/CustomCardMedia';
import usePhotoUploader from '@/src/components/photo-uploader/usePhotoUploader';
import { LoadingButton } from '@mui/lab';

const getInitialValues = () => ({
  valetImage: null,
});

export const validate = (t: (par1: string, par2?: TLanguagesOptions) => string, code: string) =>
  Yup.object().shape({
    valetImage: Yup.string().when([], {
      is: () => code === 'Pending',
      then: (schema) => schema.required(t('required_field', { ns: 'errors' })),
      otherwise: (schema) => schema,
    }),
  });

const InfoValet = ({ close, row, setSnackBarMessage }: any) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { data: valetDetail, isLoading } = useQuery(['valetDetail'], () => getValetById(row?.id), {
    select: (data) => data?.data?.request,
  });

  useEffect(() => {
    queryClient.resetQueries(['valetDetail']);
  }, [queryClient]);

  const { mutate: mutateChangeState } = useMutation(ChangeDeliveryStatePackage, {
    onSuccess: () => {
      setSnackBarMessage(t('delivered_valet_success_message', { ns: 'packages' }));
    },
    onError: () => {
      setSnackBarMessage(t('delivered_valet_error_message', { ns: 'packages' }));
    },
  });

  const onSubmit = (data: FormikValues) => {
    const changeStateRequest = { id: valetDetail?.id, valetImage: data?.valetImage };
    mutateChangeState(changeStateRequest);
  };

  const { handleSubmit, values, setFieldValue, errors } = useFormik({
    initialValues: getInitialValues(),
    onSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: validate(t, valetDetail?.state?.code),
  });

  const deleteImage = (value: keyof typeof values) => {
    setFieldValue(value, null);
  };

  const { PhotoUploader, wrongPhotos: wrongCoverPhotos } = usePhotoUploader({
    htmlFor: 'image-header',
    values,
    valueName: 'valetImage',
    setFieldValue,
  });

  const lang = getLanguage()?.split('-')[0];

  return (
    <Box component="div" sx={{ marginY: '5px', backgroundColor: 'white' }}>
      <Grid component="form" container spacing={2} onSubmit={handleSubmit} sx={{ backgroundColor: 'white' }}>
        <Grid item xs={12} sx={{ pt: '0 !important' }}>
          <Typography variant="subtitle1" fontWeight={600}>
            {t('confirm_package_sub_title', { ns: 'packages' })}
          </Typography>
          <Divider sx={{ mt: '2px', mb: '5px' }} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Typography component="span" variant="subtitle2">
            {t('attached_delivered_image', { ns: 'packages' })}
          </Typography>
        </Grid>
        {values.valetImage ? (
          <Grid item xs={8} sm={8} md={8} sx={{ position: 'relative', m:'0px 15px', height: '250px' }}>
            <Tooltip title={t('remove_item_tooltip_msn', { ns: 'packages' })}>
              <IconButton onClick={() => deleteImage('valetImage')} sx={{ position: 'absolute', right: -15, top: -14 }}>
                <CancelRoundedIcon />
              </IconButton>
            </Tooltip>
            <CustomCardMedia src={values?.valetImage} alt="cover-image" height="250px" />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={8} sx={{ position: 'relative', m:'0px 15px', height: '250px' }}>
             <PhotoUploader/>
             <ErrorText error={wrongCoverPhotos?.code} />
             {errors?.valetImage && <ErrorText error={t('attach_delivered_image', { ns: 'packages' })} />}
           </Grid>
        )}
        <Grid container item xs={12} justifyContent="flex-end">
          <LoadingButton
            size="small"
            variant="contained"
            color="success"
            type="submit"
            disabled={!values?.valetImage}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
          >
            {t('modal_valet_info_change_state_confirm', { ns: 'packages' })}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InfoValet;
