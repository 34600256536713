import { Box, Button, Grid, Typography } from '@mui/material';
import { LoadPackageModalProps } from '../../../types';
import { ApartmentContainer, StyledButton } from '../LoadPackageForm.styled';
import { PropertyType } from '@/src/pages/residents/residents-section/types';

const LoadPackageFormStep3 = ({
  setCurrentStep,
  t,
  values,
  setFieldValue,
  propertiesList,
}: LoadPackageModalProps | any) => {
  const apartments = propertiesList
    .filter((p: PropertyType) => {
      const splitFloor = p.code.split('-');
      return Number(splitFloor[0]) === Number(values.property_code);
    })
    ?.map((v: any) => v.code.split('-').pop());

  const setPropertyCode = (apartment: string) => {
    const baseList = propertiesList.filter((p: PropertyType) => {
      const splitFloor = p.code.split('-');
      return Number(splitFloor[0]) === Number(values.property_code);
    });
    console.log(baseList.filter((l: PropertyType) => l.code.includes(apartment)));
    return baseList.filter((l: PropertyType) => l.code.includes(apartment))[0].code as string;
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2" sx={{ padding: '10px 0' }}>
            {t('load_package_step_3_subtitle', { ns: 'packages' })}
          </Typography>
        </Grid>
      </Grid>
      <ApartmentContainer container>
        {apartments.map((apartment: string) => (
          <Grid item key={`apartment_${apartment}}`} xs={4} sm={3}>
            <StyledButton
              variant="outlined"
              color="primary"
              size="small"
              type="button"
              onClick={() => {
                setFieldValue('property_code', setPropertyCode(apartment));
                setCurrentStep(4);
              }}
            >
              {apartment}
            </StyledButton>
          </Grid>
        ))}
      </ApartmentContainer>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          sx={{ ml: { sm: '10px', xs: '0px' }, mt: 2, width: { xs: '100%', sm: 'auto' } }}
          type="button"
          onClick={() => {
            setFieldValue('property_code', '');
            setCurrentStep(2);
          }}
          variant="outlined"
          size="small"
        >
          {t('back')}
        </Button>
      </Box>
    </Box>
  );
};

export default LoadPackageFormStep3;
