import { useQuery } from '@tanstack/react-query';
import { getAdministrationMenus } from '@/src/api/endpoints/administration';

export const useGetMenus = (clientTenantCode: string) => {
  const {
    data: menuList,
    isLoading,
    refetch,
  } = useQuery(['menus'], () => getAdministrationMenus(), {
    select: (data) => data?.data?.menus,
    enabled: false,
  });

  return { menuList, isLoading, refetch };
};
