import { useTranslation } from 'react-i18next';
import { Delete, Visibility, QrCode2 } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { CustomDataTableColumnsProps } from '@/src/components/custom-data-table/types/CustomDataTableColumnProps';
import { ActionColumn, ActionHeader } from '@/src/components/custom-data-table/types/DataTableFormModal';
import CustomTableAction from '@/src/components/custom-data-table/components/CustomTableAction';
import { getLanguage } from '@/src/utilities/storage';
import EditIcon from '@mui/icons-material/Edit';
import { packagesTypes } from '../types';
import CreatePackageTypeFormContainer from '../components/create-package-type-form/CreatePackageTypeForm.container';
import InfoPackageType from '../components/info-package-type/PackageTypeDetailForm.container';
import PrintLabel from '../components/print-label/PrintLabel';
import DeletePackageType from '../components/delete-packageType/DeletePackageType';
import EditPackageForm from '../components/edit-package-type-form/EditPackageTypeForm.container';

export const useColumns = () => {
  const { t } = useTranslation();
  const lang = getLanguage()?.split('-')[0];

  const columns: CustomDataTableColumnsProps<packagesTypes>[] = [
    {
      id: 'id',
      sortField: 'id',
      name: t('packages_page_table_column_idInternal', { ns: 'packages' }),
      selector: (row: packagesTypes) => row?.id,
      sortable: true,
      center: true,
      filters: [
        {
          id: 'id',
          query: 'id',
          type: 'numeric',
        },
      ],
    },
    {
      id: 'name',
      sortField: 'name',
      name: t('packages_page_table_column_name', { ns: 'packages' }),
      selector: (row: packagesTypes) => row?.name,
      sortable: true,
      center: true,
    },
    {
      id: 'price',
      sortField: 'price',
      name: t('packages_page_table_column_price', { ns: 'packages' }),
      selector: (row: packagesTypes) => row.price,
      sortable: true,
      center: true,
    },
  ];

  const headerActions: ActionHeader[] = [
    {
      id: 'packages-type-create',
      component: () => (
        <CustomTableAction
          modalTitle="package_types"
          component={(props: any) => <CreatePackageTypeFormContainer {...props} />}
          Icon={() => <AddIcon />}
          type="create"
          width={{ xs: '90%', sm: '530px' }}
        />
      ),
    },
  ];

  const actionColumns: ActionColumn[] = [
    {
      id: 'detail',
      icon: (props: any) => <Visibility sx={props.sx} />,
      customizedTitle: true,
      component: (props: any) => <InfoPackageType {...props} />,
      width: { xs: '96%', sm: '560px' },
    },
    {
      id: 'edit',
      icon: (props: any) => <EditIcon sx={props.sx} />,
      component: (props: any) => <EditPackageForm {...props} />,
      width: { xs: '96%', sm: '560px' },
    },
    {
      id: 'delette',
      icon: (props: any) => <Delete sx={props.sx} />,
      component: (props: any) => <DeletePackageType {...props} />,
      width: '25%',
    },
  ];

  return {
    columns,
    headerActions,
    actionColumns,
  };
};
