import React, { createContext, ReactNode, useMemo } from 'react';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, MessagePayload } from 'firebase/messaging';
import { AlertColor } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CountersObject, useGetCounters } from '../hooks/common/items-w-counter/useGetCounters';
import { firebaseConfig, responseConfig } from '../utilities/firebase/config';
import { NotificationObject } from '../utilities/helpers/commonTypes';
import { AuthContext } from './auth.context';
import { getLanguage } from '../utilities/storage';
import { extractFirstDecimalNumberFromString } from '../utilities/helpers/utils';
import { FirebaseLoginRequest } from '../api/types/types';
import { useLogin } from '../hooks/auth/useLoginHandler';

type IntialState = {
  countersData: CountersObject;
  notificationInfo: NotificationObject | null;
  setNotificationInfo: React.Dispatch<React.SetStateAction<NotificationObject | null>>;
  forceRefresh: (abort?: any) => void;
  firebaseToken: string;
  setFirebaseToken: React.Dispatch<React.SetStateAction<string>>;
  hasNewConnection: boolean;
  setHasNewConnection: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FirebaseContext = createContext({} as IntialState);

const FirebaseContextProvider = ({
  children,
  setSnackBarMessage,
}: {
  children: ReactNode;
  setSnackBarMessage: (msj: string, sever?: AlertColor) => void;
}) => {
  const app = initializeApp(firebaseConfig);
  const firebaseMessaging = getMessaging(app);
  const { t } = useTranslation('notifications');
  const { loginStatus } = React.useContext(AuthContext);
  const [notificationInfo, setNotificationInfo] = React.useState<NotificationObject | null>(null);
  const [firebaseToken, setFirebaseToken] = React.useState<string>('');
  const [hasNewConnection, setHasNewConnection] = React.useState<boolean>(false);
  const [serviceWorkerRegistration, setRegistration] = React.useState<ServiceWorkerRegistration>();
  const { countersData, forceRefresh } = useGetCounters(true);
  const { onFirebaseLogin } = useLogin({});
  const getFirebaseToken = async () => {
    const firebaseTokenRegistered = await getToken(firebaseMessaging, { serviceWorkerRegistration });
    setFirebaseToken(firebaseTokenRegistered);
    // console.log(firebaseTokenRegistered);
    return firebaseTokenRegistered;
  };

  const handlePushNotification = (payload: MessagePayload) => {
    const response = payload.data ?? null;
    Object.keys(responseConfig).forEach((item) => {
      if (response) {
        const eventCode = response?.EventCode ?? null;
        if (eventCode === item) {
          setNotificationInfo(responseConfig[eventCode]);
        }
      }
    });
    setSnackBarMessage(payload.notification?.title ?? t('default_response_message'), 'info');
  };

  const handleFirebaseLogin = async () => {
    const firebaseT = await getFirebaseToken();
    const storageGuid = localStorage.getItem('deviceId');
    const guid = storageGuid ?? crypto.randomUUID();
    localStorage.setItem('deviceId', guid);
    const lang = getLanguage();
    const { navigator } = window;
    const systemVersion = extractFirstDecimalNumberFromString(navigator.appVersion);

    const req: FirebaseLoginRequest = {
      deviceId: guid,
      body: {
        firebase_token: firebaseT,
        language_code: lang,
        platform: navigator.platform,
        brand: navigator.vendor,
        model: navigator.appCodeName,
        system_version: systemVersion,
      },
    };
    await onFirebaseLogin(req);
  };

  React.useEffect(() => {
    localStorage.setItem('loginStatus', loginStatus);
    if (loginStatus === 'authenticated' && serviceWorkerRegistration && hasNewConnection) {
      handleFirebaseLogin();
    }
  }, [loginStatus, serviceWorkerRegistration]);

  React.useEffect(() => {
    const registrarServiceWorker = async () => {
      if (localStorage.getItem('accessToken')) {
        try {
          const reg = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            type: 'module',
          });
          setRegistration(reg);
          // console.log('Service Worker registrado con éxito:', reg);
        } catch (error) {
          console.error('Error al registrar el Service Worker:', error);
        }
      }
    };

    registrarServiceWorker();
  }, [localStorage.getItem('accessToken')]);

  React.useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (payload) => {
      const { data } = payload;
      if (data.messageType === 'push-received') {
        handlePushNotification(data);
      }
      forceRefresh();
    });
  }, []);

  const memoedValue = useMemo(() => {
    const value = {
      countersData,
      notificationInfo,
      setNotificationInfo,
      forceRefresh,
      firebaseToken,
      setFirebaseToken,
      setHasNewConnection,
      hasNewConnection,
    };
    return value;
  }, [
    countersData,
    notificationInfo,
    setNotificationInfo,
    forceRefresh,
    firebaseToken,
    setFirebaseToken,
    setHasNewConnection,
    hasNewConnection,
  ]);

  return <FirebaseContext.Provider value={memoedValue}>{children}</FirebaseContext.Provider>;
};

export default FirebaseContextProvider;
