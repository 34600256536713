import { CircularProgress, IconButton, InputLabel } from '@mui/material';
import React, { useState } from 'react';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import { useMutation } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { imageResize, validImage } from '@/src/utilities/helpers/imageResizer';
import { createImageFile } from '@/src/api/endpoints/utils';
import { errorHandlerHelper } from '@/src/utilities/helpers/errorHandlerHelper';

interface ImageUploaderProps {
  htmlFor: string;
  valueName: string;
  values: FormikValues;
  setFieldValue: (field: string, val: string | Array<string>) => void;
  setDisableForm?: (val: boolean) => void;
}

const useImageUploader = ({ htmlFor, setFieldValue, values, valueName, setDisableForm }: ImageUploaderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useMutation(createImageFile);
  const [wrongImages, setWrongImages] = useState<{ code: any; error_messages: any } | null | undefined>(null);

  const getImageUrl = (ev: any) => {
    console.log('ev', ev);
    const submittedImage = ev.target.files[0];
    setIsLoading(true);
    setWrongImages(null);
    if (submittedImage && validImage(submittedImage)) {
      try {
        if (setDisableForm) setDisableForm(true);
        imageResize(submittedImage).then((convertedFile: any) => {
          const elementsOfUrl = convertedFile.split(',');
          mutate(
            { image: elementsOfUrl[1], file_name: submittedImage.name },
            {
              onSuccess: ({ data }) => {
                Array.isArray(values[`${valueName}`])
                  ? setFieldValue(valueName, [...values[`${valueName}`], { image_url: data.image_url }])
                  : setFieldValue(valueName, data.image_url);
                setIsLoading(false);
              },
              onError: (err) => {
                setIsLoading(false);
                setWrongImages(errorHandlerHelper(err));
              },
              onSettled: () => {
                if (setDisableForm) setDisableForm(false);
              },
            },
          );
        });
      } catch (transformationError) {
        throw new Error(transformationError as string);
      }
    } else {
      setIsLoading(false);
      setWrongImages({
        code: 'Invalid Format',
        error_messages: 'Invalid image format',
      });
    }
  };

  const ImageUploader = ({ disabled }: { disabled?: boolean }) =>
    isLoading ? (
      <CircularProgress />
    ) : (
      <IconButton>
        <InputLabel htmlFor={htmlFor}>
          <input
            id={htmlFor}
            onChange={(e: any) => getImageUrl(e)}
            style={{ display: 'none' }}
            disabled={disabled || isLoading}
            type="file"
            accept="image/*"
          />
          <AddPhotoAlternateRoundedIcon fontSize="large" sx={{ cursor: 'pointer', color: 'secondary.main' }} />
        </InputLabel>
      </IconButton>
    );

  return { ImageUploader, wrongImages };
};

export default useImageUploader;
