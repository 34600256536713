import { useTranslation } from 'react-i18next';
import { packagesTypes } from '../../types';
import CreatePackageTypeForm from './PackageTypeDetailForm';
import { getPackageTypeById } from '@/src/api/endpoints/packages';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { InferPropsExtended } from '@/src/utilities/helpers/proptypesHelper';
import { useEffect, useMemo } from 'react';

const PackageTypeDetailFormContainer = (props: Props) => {
  const { close, row } = props;
  const { t } = useTranslation();

  //get data
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['packageTypeDetail'], () => getPackageTypeById(row.id), {
    select: (data) => data?.data?.package_type as packagesTypes,
  });

  const memoizedResetQueries = useMemo(() => queryClient.resetQueries(['packageTypeDetail']), []);
  
  useEffect(() => {
    memoizedResetQueries;
  }, []);

  const childProps = {
    close,
    data,
    t,
    isLoading
  };
  
  return <CreatePackageTypeForm {...childProps} />;
};

const propTypes = {};

interface ExtraProps {
  close: () => void;
  row: {
        id: number,
        name: string,
        price: number
      }
}

interface Props extends InferPropsExtended<typeof propTypes, ExtraProps> {}
PackageTypeDetailFormContainer.propTypes = propTypes;

export default PackageTypeDetailFormContainer;
